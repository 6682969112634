<template>
  <transition>
    <router-view></router-view>
  </transition>
</template>

<script>
export default {};
</script>

<style>
</style>